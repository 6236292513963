import * as OpportunityHelper from "@ui/helpers/opportunities/helper";
import { OpportunityTypes } from '@ui/helpers/opportunities/opportunity-types';
import { theme } from 'tailwind.config';
import { brandNames } from './enums';

export const mobileDeviceTypes = {
    android: 'android',
    ios: 'ios',
    unknown: 'unknown',
};
export const adminInvestorColumns = [
    'Country Code',
    'Contact Number',
    'Name',
    'Registration Date',
    'KYC Status',
    'KYC submitted date',
    'Action',
];
export const kycInvestorColumns = [
    'Name',
    'Country Code',
    'Mobile Number',
    'Date of Upload',
    'Status',
    'Action',
];
export const entityTypes = {
    User: 'User',
    Investor: 'Investor',
};
export const consentTypes = {
    IFA_CONSENT: 'IfaConsent',
    SIGNUP_TC: 'SignUpT&C',
    NRI_DISCLAIMER: 'NriDisclaimer',
};

export const adminUrlsToEnableSearch = ['/admin/investors'];

export const WalletName = 'altSafe';
export const supportEmail = 'care@altgraaf.com';
export const supportPhoneNumber = '+91 9711321050';

export const brandName = 'altGraaf';

export const kycFilterOptions = [
    {
        label: 'All',
        value: '',
    },
    {
        label: 'Pending',
        value: 'Pending',
    },
    {
        label: 'Verified',
        value: 'Verified',
    },
    {
        label: 'Manual Verification Required',
        value: 'ManualVerificationRequired',
    },
];

export const twitterUrl = 'https://twitter.com/altgraaf';
export const facebookUrl = 'https://www.facebook.com/altGraaf.Official';
export const linkedInUrl = 'https://www.linkedin.com/company/altgraafofficial';
export const instagramUrl = 'https://www.instagram.com/altgraaf_official/';
export const youtubeUrl = 'https://youtube.com/@altGraaf?si=CQs9pZX82lfkopU-';

export const address = `86/1, 2nd Floor Maruti Complex, New Thippasandra Main Road, Next To Anjaneya Temple, Bengaluru, Karnataka, 560075`;

export const panAlreadyExistMessage = `The PAN you have entered is already associated with another account on ${brandName}.`;

export const opportunityBreadcrumbs = (value) => [
    { title: 'Opportunities', url: '/opportunities' },
    { title: value, active: true },
];

export const kycStatusModified = {
    Verified: {
        src: '/images/tick-success.svg',
        status: 'KYC Verified',
        className: 'bg-semantic-success-light text-semantic-success-base'
    },
    ManualVerificationRequired: {
        src: '/images/clock-progress.svg',
        status: 'KYC in Verification',
        className: 'text-semantic-info-base bg-semantic-info-light'
    },
    Rejected: {
        src: '/images/exclamatoryError.svg',
        status: 'KYC Rejected',
        className: 'bg-semantic-error-light text-semantic-error-base'
    },
    Pending: {
        src: '/images/clock-pending.svg',
        status: 'KYC Pending',
        className: 'text-semantic-warning-base bg-semantic-warning-light'
    },
};

export const getOpportunityType = (type) => {
    switch (type) {
        case OpportunityTypes.CORPORATE_DEBT:
            return 'Corporate Debt (Unlisted)';
        case OpportunityTypes.ASSET_BACKED_LEASING:
            return 'Asset Backed Leasing';
        case OpportunityTypes.INVOICE_DISCOUNTING:
            return 'Invoice Discounting';
        case OpportunityTypes.VENTURE_DEBT:
            return 'Venture Debt';
        case OpportunityTypes.TREASURY_BILL:
            return 'Treasury Bill';
        default:
            return 'Corporate Debt (Unlisted)';
    }
};

export const investmentReviewBreadcrumbs = (opportunity) => [
    { title: 'Opportunities', url: '/opportunities' },
    {
        title: `${getConterPartyName(opportunity)}`,
        url: `/opportunity/${opportunity.id}`,
    },
    { title: 'Investment', active: true },
];

const quickOptions = [1, 2, 3];

export const quickAmountSelectionOptions = (multiple) => {
    return quickOptions.map((option) => option * multiple);
};
export const otpExpiryTime = 60;

export const staticOpportunity = (id) =>
    ['2d688a3c-3f76-4003-843a-2b7152c5eb01'].includes(id);

export const addressProofTypes = [
    { label: 'Aadhar Card', value: 'Aadhar' },
    { label: 'Voter ID', value: 'VoterId' },
    { label: 'Passport', value: 'Passport' },
];

export const kycImagePrefix = process.env.NEXT_PUBLIC_ADDRESS_PROOF_PREFIX;

export const INVESTOR_KYC_BUCKET = process.env.NEXT_PUBLIC_INVESTOR_KYC_BUCKET;

export const OPPORTUNITY_INVESTORS_BUCKET =
    process.env.NEXT_PUBLIC_OPPORTUNITY_INVESTORS_BUCKET;

export const INVESTOR_OPPORTUNITY_DOCUMENTS_BUCKET =
    process.env.NEXT_PUBLIC_INVESTOR_OPPORTUNITY_DOCUMENTS_BUCKET;

export const getHeaderForKycDocumentUpload = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return 'Upload Aadhaar';
        case 'VoterId':
            return 'Upload Voter ID';
        case 'Passport':
            return 'Upload Passport';
        default:
            break;
    }
};

export const getDoubleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                front: {
                    header: 'Upload front side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-front.png',
                },
                back: {
                    header: 'Upload back side of Aadhaar',
                    defaultImageUrl: '/images/investors/kyc/aadhar-back.png',
                },
            };
        case 'VoterId':
            return {
                front: {
                    header: 'Upload front side of voter ID',
                    defaultImageUrl: '/images/investors/kyc/voterId-front.png',
                },
                back: {
                    header: 'Upload back side of voter ID',
                    defaultImageUrl: '/images/investors/kyc/voterId-back.png',
                },
            };
        case 'Passport':
            return {
                front: {
                    header: 'Upload photo page of passport',
                    defaultImageUrl: '/images/investors/kyc/passport-front.png',
                },
                back: {
                    header: 'Upload address page of passport',
                    defaultImageUrl: '/images/investors/kyc/passport-back.png',
                },
            };
        default:
            break;
    }
};

export const getSingleUploadData = (addresType) => {
    switch (addresType) {
        case 'Aadhar':
            return {
                defaultImageUrl: '/images/investors/kyc/aadhar-full.png',
            };
        case 'VoterId':
            return {
                defaultImageUrl: '/images/investors/kyc/voterId-full.png',
            };
        case 'Passport':
            return {
                defaultImageUrl: '/images/investors/kyc/passport-full.png',
            };
        default:
            break;
    }
};

export const getSingleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: 'Have two sided Aadhaar?',
        VoterId: 'Have two sided Voter ID?',
        Passport: 'Have two sided Passport?',
    };
    return addressTypes[addressType];
};

export const getDoubleUploadBottomText = (addressType) => {
    const addressTypes = {
        Aadhar: 'Have single page Aadhaar?',
        VoterId: 'Have single page Voter ID?',
        Passport: 'Have single page Passport?',
    };
    return addressTypes[addressType];
};

export const allowedFileUploads =
    'image/png, image/jpeg, image/jpg, application/pdf, image/tiff';

export const defaultUploadedPdfIcon =
    '/images/investors/kyc/default-pdf-icon.svg';

export const opportunityStates = {
    InDraft: {
        displayName: 'In Draft',
        color: 'bg-yellow-300',
    },
    SubcriptionReady: {
        displayName: 'Open for Subscription',
        color: 'bg-yellow-600',
    },
    InvestmentReady: {
        displayName: 'Open for Investment',
        color: 'bg-green-400',
    },
    OngoingRepayment: {
        displayName: 'Ongoing Repayment',
        color: 'bg-semantic-success-base',
    },
    PrepaidAndClosed: {
        displayName: 'Prepaid And Closed',
        color: 'bg-purple-700',
    },
    MaturedAndClosed: {
        displayName: 'Matured And Closed',
        color: 'bg-basicBlack',
    },
};

export const navbarMenu = [
    { title: 'Opportunities', url: '/opportunities' },
    { title: 'Dashboard', url: '/dashboard' },
];

export const isNri = (countryOfOrigin = '') => {
    return countryOfOrigin.toLowerCase() !== 'india';
};

export const isCountryOfOriginIndia = (countryCode) => countryCode === '91';

export const getConterPartyName = (opportunity) => {
    switch (opportunity.type) {
        case OpportunityTypes.CORPORATE_DEBT:
        case OpportunityTypes.TREASURY_BILL:
        case OpportunityTypes.VENTURE_DEBT:
            return opportunity.primaryCounterParty?.name;
        case OpportunityTypes.ASSET_BACKED_LEASING:
            return opportunity.lessee?.name;
        case OpportunityTypes.INVOICE_DISCOUNTING: {
            if (opportunity?.brand?.name === brandNames.ALT_BLU) {
                return opportunity.anchor?.name;
            }
            return opportunity.primaryCounterParty?.name;
        }
        default:
            return opportunity.borrower?.name;
    }
};

export const getConterPartyLogo = (opportunity) => {
    switch (opportunity.type) {
        case OpportunityTypes.CORPORATE_DEBT:
        case OpportunityTypes.TREASURY_BILL:
        case OpportunityTypes.VENTURE_DEBT:
            return opportunity.primaryCounterParty?.logo?.url;
        case OpportunityTypes.ASSET_BACKED_LEASING:
            return opportunity.lessee?.logo?.url;
        case OpportunityTypes.INVOICE_DISCOUNTING: {
            if (opportunity?.brand?.name === brandNames.ALT_BLU) {
                return opportunity.anchor?.logo?.url;
            }
            return opportunity.primaryCounterParty?.logo?.url;
        }
        default:
            return opportunity.borrower?.logo?.url;
    }
};

export const getTags = (opportunity) => {
    const sector = opportunity?.industryType?.name;
    const assetGroup = opportunity?.displayAssetClass;
    const opportunityType = opportunity?.type;
    const brandName = opportunity?.brand?.name;
    const isReinvestable = opportunity?.isReinvestable;

    const additionalTags = opportunity?.tags;

    const tags = [
        {
            label: assetGroup,
            badgeColor: getOpportunityTypeBadgeColor(opportunityType),
        },
    ];

    if (brandName !== brandNames.ALT_PACK) {
        tags.push({
            label: sector,
            badgeType: 'ghost'
        });
    }

    if (additionalTags && additionalTags.length > 0) {
        tags.push(
            ...additionalTags.map((tag) => ({
                label: tag,
                badgeType: 'ghost'
            }))
        );
    }

    if (isReinvestable) {
        tags.push({
            label: 'Reinvest Deal',
            badgeType: 'special',
        })
    }
    return tags;
};

export const isUpcomingOpportunity = (opportunity) => {
    return opportunity.state === 'InDraft';
};
// DEPRICATED!
export const isInvoiceDiscountingOpportunity = (opportunityType) => OpportunityHelper.isInvoiceDiscountingOpportunity(opportunityType);
// DEPRICATED!
export const isCorporateDebtOpportunity = (opportunityType) => OpportunityHelper.isCorporateDebtOpportunity(opportunityType);
// DEPRICATED!
export const isVentureDebtOpportunity = (opportunityType) => OpportunityHelper.isVentureDebtOpportunity(opportunityType);
// DEPRICATED!
export const isTreasuryBillOpportunity = (opportunityType) => OpportunityHelper.isTreasuryBillOpportunity(opportunityType);

export const isComboDeal = (opportunity) =>
    opportunity.bundledType === 'Parent';

export const isComboDealChild = (investment) =>
    investment.bundledType === 'Child';

export const isCommercialDisplayAssetClass = (displayAssetClass) =>
    displayAssetClass && displayAssetClass.toLowerCase() === 'commercial paper';

export const getConterPartyAbout = (opportunity) => {
    switch (opportunity.type) {
        case OpportunityTypes.CORPORATE_DEBT:
        case OpportunityTypes.TREASURY_BILL:
        case OpportunityTypes.VENTURE_DEBT:
            return opportunity.borrower?.about;
        case OpportunityTypes.ASSET_BACKED_LEASING:
            return opportunity.lessee?.about;
        case OpportunityTypes.INVOICE_DISCOUNTING:
            return opportunity.primaryCounterParty?.about;
        default:
            return opportunity.borrower?.about;
    }
};

export const getPayoutFrequencyTitle = (opportunity) => {
    switch (opportunity.type) {
        case OpportunityTypes.ASSET_BACKED_LEASING:
            return 'Payout Frequency';
        case OpportunityTypes.INVOICE_DISCOUNTING:
            return 'Payout Frequency';
        default:
            return 'Principal Payout Frequency';
    }
};

export const opportunityClosedStates = [
    'OngoingRepayment',
    'PrepaidAndClosed',
    'MaturedAndClosed',
];

export const opportunityFullyRepaidStates = [
    'PrepaidAndClosed',
    'MaturedAndClosed',
];

const teamBaseUrl = {
    linkedIn: 'https://www.linkedin.com/in',
    imageUrl:
        'https://altgraaf-public-static-images.s3.ap-south-1.amazonaws.com/team-page',
};

export const TeamImageUrl = `${teamBaseUrl.imageUrl}/team.png`;

export const TeamMembers = [
    {
        title: 'Founders',
        members: [
            {
                name: 'Saurav Ghosh',
                designation: 'Founder',
                description:
                    'Saurav has 10 + years’ experience in corporate finance with leading Real Estate developers. Alumnus of BITS, Pilani, Goa Campus & IIM-A.',
                linkedIn: `${teamBaseUrl.linkedIn}/saurav-ghosh-5345905/`,
                photo: `${teamBaseUrl.imageUrl}/Saurav.png`,
            },
            {
                name: 'Vineet Agrawal',
                designation: 'Founder',
                description:
                    'Vineet has 12+ years’ experience with Real Estate and Hospitality businesses, managing end to end life cycle of investments. Alumnus of RVCE & XIM-B.',
                linkedIn: `${teamBaseUrl.linkedIn}/vineet-agrawal-8aa5359/`,
                photo: `${teamBaseUrl.imageUrl}/Vineet.png`,
            },
        ],
    },
    {
        title: 'TeamHeads',
        members: [
            {
                name: 'Vikas Bansal',
                designation: 'Head - Investments',
                description:
                    'Vikas comes with 14+ years across Corporate Banking, his last stint was with Kotak Mahindra Bank, alumnus of IIM Lucknow & IIT Kharagpur.',
                linkedIn: `${teamBaseUrl.linkedIn}/bonsikool/`,
                photo: `${teamBaseUrl.imageUrl}/Vikas.png`,
            },
            {
                name: 'Prateek Goyal',
                designation: 'Head - Strategy',
                description:
                    'Prateek comes with 10+ years across investment banking, private equity & venture capital, alumnus of IIM-A.',
                linkedIn: `${teamBaseUrl.linkedIn}/prateek-goyal-43a46429/`,
                photo: `${teamBaseUrl.imageUrl}/Prateek.png`,
            },
            {
                name: 'Jayaprakash K',
                designation: 'Head - Growth',
                description:
                    'An MBA grad from Gies College of Business, JP has 18+ years of experience in the banking and finserv industries. He has earlier worked at HSBC, Home Credit and Cohen & Steers.',
                linkedIn: `${teamBaseUrl.linkedIn}/jp-kandasami/`,
                photo: `${teamBaseUrl.imageUrl}/Jayaprakash.png`,
            },
            {
                name: 'Vasu Gulati',
                designation: 'Head - Engineering',
                description:
                    'An alumnus of NIT Rourkela, Vasu has 17+ years of experience in designing & developing SaaS, B2C, and B2B systems. He has previously worked at ShipBob Inc, Barclays and Cognizant.',
                linkedIn: `${teamBaseUrl.linkedIn}/vasugulati/`,
                photo: `${teamBaseUrl.imageUrl}/Vasu.png`,
            },
            {
                name: 'Apoorv Gawde',
                designation: 'Head - Product',
                description:
                    'Apoorv has 17+ years of experience in leading product teams to build and deliver customer value across domains. He has been instrumental in delivering multiple fintech platforms over the last 6 years.',
                linkedIn: `${teamBaseUrl.linkedIn}/apoorvgawde/`,
                photo: `${teamBaseUrl.imageUrl}/Apoorv.png`,
            },
            {
                name: 'Ankit Bhartia',
                designation: 'Head - Risk and Finance',
                description:
                    'Ankit is a Real Estate Expert with 14 yrs of experience across Credit and Relationship Management in Wholesale Real Estate Funding division of HSBC, ICICI Bank, Yes Bank and Piramal Capital, alumnus of IIM Kozhikode. CFA and FRM certified.',
                linkedIn: `${teamBaseUrl.linkedIn}/ankitbhartia/`,
                photo: `${teamBaseUrl.imageUrl}/Ankit.png`,
            },
        ],
    },
];

export const maxFileSize = 2000;

export const confidenceScore = 0.65;

export const userColumns = [
    'Country Code',
    'Mobile Number',
    'Signup Date',
    'Email',
];

export const bankVerificationSuccessfulStatusCode = 101;
export const maxRetries = 3;

export const karzaServerStatusCodes = {
    400: {
        description: 'Bad Request',
    },
    503: {
        description: 'Service Unavailable',
    },
    504: {
        description: 'Request Timed Out',
    },
    402: {
        description: 'Insufficient Credits',
    },
};

export const karzaInternalStatusCodes = {
    101: {
        action: 'Approval',
        description: 'Valid Authentication',
    },
    102: {
        customMessage: 'Please enter valid Bank Details',
        action: 'Alert',
        description: 'Invalid ID number or combination of inputs',
    },
    104: {
        action: 'Manual',
        description: 'Max Retries Exceeded',
    },
    107: {
        action: 'Manual',
        description: 'Not Supported',
    },
    108: {
        action: 'Manual',
        description: 'Internal Resource Unavailable',
    },
};

export const bankVerificationKYCStatusCodes = {
    KC01: {
        customMessage: 'Transaction Successful',
        action: 'Approval',
        description: 'Transaction Successful',
        adminMessage: 'Approval',
        pennyDropMessage: 'Approval',
    },
    KC02: {
        action: 'Manual',
        description: 'Credit Transaction Failed',
    },
    KC03: {
        customMessage:
            'Either your bank account number or IFSC code is incorrect. Please enter valid details and retry.',
        action: 'Alert',
        description: 'Invalid Beneficiary Account Number or IFSC',
        adminMessage:
            "Please check Either investor's Bank account Number or IFSC code is incorrect",
        pennyDropMessage:
            'Please check whether the bank account number or IFSC code is incorrect',
    },
    KC04: {
        action: 'Manual',
        description: 'Amount Limit Exceeded',
    },
    KC05: {
        customMessage: 'Please make sure your bank account is Active',
        action: 'Alert',
        description: 'Account Blocked/Frozen',
        adminMessage: "Please check Investor's Account might not be Active",
        pennyDropMessage:
            'The bank account might not be active. Please verify.',
    },
    KC06: {
        customMessage:
            'This is a NRE account. Please provide another bank account',
        action: 'Alert',
        description: 'NRE Account',
        adminMessage:
            'This is a NRE account. Please ask Investor to provide another account',
        pennyDropMessage:
            'This is a NRE account. Please provide another bank account',
    },
    KC07: {
        customMessage:
            'We are not able to verify this account. Please ensure that your bank account is Active. Alternately provide a different account',
        action: 'Alert',
        description: 'Account Closed',
        adminMessage: "Please check Investor's Account might be Closed",
        pennyDropMessage: 'The bank account might be closed. Please verify.',
    },
    KC08: {
        action: 'Manual',
        description: 'Limit Exceeded For Member Bank',
    },
    KC09: {
        action: 'Manual',
        description: 'Transaction Not Allowed',
    },
    KC10: { action: 'Manual', description: 'Function Not Valid' },
    KC11: {
        action: 'Manual',
        description: 'Aadhaar Belong To Remitter Bank',
    },
    KC12: {
        action: 'Manual',
        description: 'Transaction Not Allowed',
    },
    KC13: {
        action: 'Manual',
        description: 'Customer Transaction Limit Exceeded',
    },
    KC14: {
        action: 'Approval',
        description: 'Payee Is An Individual And Not A Merchant',
        adminMessage: 'Approval',
        pennyDropMessage: 'Approval',
    },
    KC15: {
        action: 'Approval',
        description: 'Payee Is A Merchant And Not An Individual',
        adminMessage: 'Approval',
        pennyDropMessage: 'Approval',
    },
    KC16: {
        customMessage:
            'This is a NRE account. Please provide another bank account',
        action: 'Alert',
        description: 'Foreign Inward Remittance Not Allowed',
        adminMessage:
            'This is a NRE account. Please ask Investor to provide another account',
        pennyDropMessage:
            'This is a NRE account. Please provide another bank account.',
    },
    KC17: {
        action: 'Manual',
        description: 'Transaction Not Allowed As Invalid Payment Reference',
    },
    KC18: { action: 'Manual', description: 'Invalid Amount' },
    KC19: {
        action: 'Manual',
        description: 'Invalid Remitter Account Number',
    },
    KC20: { action: 'Manual', description: 'General Error' },
    KC21: {
        customMessage:
            'Please check your Bank Account Number or IFSC code is incorrect',
        action: 'Manual',
        description: 'Invalid Transaction Type',
        pennyDropMessage:
            'Please check whether your bank account number or IFSC code is incorrect.',
    },
    KC22: {
        action: 'Manual',
        description: 'Invalid Amount Field',
    },
    KC23: {
        action: 'Manual',
        description: 'IMPS Service not available for the selected bank',
    },
    KC24: {
        action: 'Manual',
        description: 'Duplicate Transaction',
    },
    KC25: {
        action: 'Manual',
        description: 'Beneficiary Bank Not Enable For P2a',
    },
    KC26: {
        action: 'Manual',
        description: 'Insufficient Balance In Pool A/C',
    },
    KC27: {
        customMessage:
            'We are not able to verify this account. Please ensure that your account details are valid',
        action: 'Alert',
        description: 'Invalid Account',
        pennyDropMessage:
            'We are not able to verify this account. Please ensure that the account details are valid.',
    },
    KC30: {
        action: 'Manual',
        description: 'Invalid Response Code',
    },
    KC31: {
        action: 'Manual',
        description: 'Exceeds Account Limit',
    },
    KC32: { action: 'Manual', description: 'Unable To Process' },
    KC33: { action: 'Manual', description: 'Invalid Message' },
    KC34: { action: 'Manual', description: 'Suspected Fraud' },
    KC35: {
        action: 'Manual',
        description: 'Transfer Amount Exceeds Limit',
    },
    KC36: { action: 'Manual', description: 'Key Sync Error' },
    KC37: { action: 'Manual', description: 'Timed Out' },
    KC38: { action: 'Manual', description: 'CBS Offline' },
    KC39: { action: 'Manual', description: 'Unmapped Error' },
    KC40: {
        customMessage:
            'We are not able to verify this account. Please ensure that you have provided the right IFSC code',
        action: 'Alert',
        description: 'Invalid Beneficiary IFSC Code or NBIN',
        adminMessage: 'Please check the IFSC code',
        pennyDropMessage: 'Please check the IFSC code.',
    },
    KC41: {
        action: 'Manual',
        description: 'Transaction Not Allowed',
    },
    KC42: {
        action: 'Manual',
        description: 'Foreign Inward Remittance For P2P Only',
    },
    KC43: { action: 'Manual', description: 'Invalid Channel' },
    KC44: {
        action: 'Manual',
        description: 'Authentication Failure',
    },
    KC45: {
        action: 'Manual',
        description: 'Invalid Account Type',
    },
    KC46: {
        action: 'Manual',
        description: 'Invalid Merchant Account',
    },
    KC47: {
        action: 'Manual',
        description: 'Invalid Remitter Name',
    },
    KC48: {
        action: 'Manual',
        description:
            'Successful Transaction With Generic Name Returned In Account Name',
    },
    KC49: {
        action: 'Manual',
        description:
            'Transaction Successful, Recipient Name Not Provided By Bank',
    },
    KC52: {
        action: 'Manual',
        description: 'Duplicate Reference Number',
    },
    KC56: {
        action: 'Manual',
        description: 'Response Code Not Maintained',
    },
};

export const netbankingLimitAmount = 300000;
// TODO: Check imports
// DEPRICATED!
export const assetClassContainUnits = [
    'CorporateDebt',
    'VentureDebt',
    'TreasuryBill',
    'CommercialPaper',
];
// DEPRICATED!
export const assetClassDoesNotContainUnits = [
    'InvoiceDiscounting',
    'AssetBackedLeasing',
];
// DEPRICATED!
export const isUnitsBased = (type) => assetClassContainUnits.includes(type);
// DEPRICATED!
export const isAmountBased = (type) =>
    assetClassDoesNotContainUnits.includes(type);

const getFAQId = (string) =>
    string.split(' ').join('-').replace('&', 'and').toLowerCase();

const referAndEarnquestionBank = [
    {
        question: 'Who can participate in the referral program?',
        answer: `Any user who has registered on ${brandName} is eligible to take part in the referral program and can refer their friends and family members. However, the user will be eligible for receiving their referral reward only after completing their KYC and providing their bank account details.`,
    },
    {
        question: 'How many people can I refer?',
        answer: `There is no limit on the number of friends that you can refer.`,
    },
    {
        question:
            `Can I refer my friend who is already an existing registered user on ${brandName}?`,
        answer: `No, all your referrals need to be new users who have registered on the ${brandName} platform using your referral link.`,
    },
    {
        question:
            'What is the minimum amount of investment my referral must make to be eligible for the program?',
        answer: 'The minimum amount of investment is INR 95,000.',
    },
    {
        question:
            'What would be my referral reward for referring 10 friends?',
        answer: `Any user referring 10 friends can potentially receive a referral reward of <span class='whitespace-nowrap'>(2 * ₹1,500) + </span> <span class='whitespace-nowrap'>(8 * ₹2,500)</span> = ₹23,000, while each of the 10 friends who were referred will be receiving a reward of ₹1,500 post making their first investment on the ${brandName} platform.`,
        haveInnerHtml: true,
    },
    {
        question: 'When do I earn the referral reward?',
        answer: `You will earn the reward once your referrals have made their first investment of at least ₹95,000 or more on ${brandName}.`,
    },
    {
        question:
            'Do I earn referral reward if my referral did not use my referral link?',
        answer: 'No. Your friends must use your referral link to register & make the required investment within 60 days for you and your friends to qualify for rewards.',
    },
    {
        question:
            'Will I be eligible for the referral rewards if I haven’t invested on the platform while my friend has made an investment?',
        answer: 'Yes, you will be eligible for the referral reward even if you haven’t made an investment, but your referral has made their first investment.',
    },
    {
        question:
            'My friend made an investment before I could complete my KYC. Will I still be eligible for the referral rewards?',
        answer: 'You will be eligible for the referral rewards as and when you complete your KYC, and the rewards will be credited to your account on or before the 15th of the next month from the date of completion of your KYC.',
    },
    {
        question: 'What is the validity of the referral program?',
        answer: 'The referral program does not have an end date. But every referral reward is valid ONLY for 60 days from the date of registration of the referred user. Both the referrer and referee will not be eligible for rewards if the referee makes their first investment beyond 60 days after their registration date. To qualify for the rewards, all the referrals should have successfully invested at least ₹95,000 or more, and the referrer should have completed their KYC and provided their bank account details.',
    },
    /**
     * Removed as per story JT-21693
     */
    /*{
        question:
            'I had referred a friend before April 15, 2023, and they haven’t made their first investment yet. Should they invest within 60 days from the date of their registration?',
        answer: `Any referred user who registered on the ${brandName} platform before April 15, 2023, should make their first investment before June 15, 2023, to enjoy referral rewards.`,
    },*/
    {
        question:
            'Will me and my friend be eligible for rewards if my friend makes two separate investments of ₹50,000 each thereby achieving a minimum of ₹1,00,000 investment on the platform?',
        answer: 'Yes, you and your friend will be eligible for the reward when your friend makes a total investment of ₹95,000  or more split across two investment opportunities. The total investment of ₹95,000 or more should have been done within 60 days of registration.',
    },
    {
        question:
            'Will I be eligible for receiving referral rewards if my friend makes an investment after 60 days of their registration date?',
        answer: 'No, you and your friend will not be eligible for receiving referral rewards if their first investment date falls after 60 days from the date of registration on the platform.',
    },
    {
        question: 'Where can I view my referrals?',
        answer: 'Your referrals and their eligibility status can be viewed in your profile Dashboard under the My Referrals section.',
    },
    {
        question: 'When will I get my pay-out?',
        answer: 'All referral rewards that you have earned in a month will be paid out on or before the 15th of the following month.',
    },
    {
        question: 'Will my referral link expire at any point of time?',
        answer: 'No, your referral link will not expire for any friend to whom it has been shared. Your friend can use it to register any time they wish but will have to make an investment within 60 days from the date of registration so that you and your friend enjoy the referral benefit.',
    },
]

export const referAndEarnFaq = [
    {
        header: 'Eligibility',
        id: getFAQId('Eligibility'),
        defaultOpen: true,
        questionBank: referAndEarnquestionBank,
    },
];

export const referAndEarnLogoutFaq = [
    {
        header: 'Refer & Earn FAQs',
        id: getFAQId('Refer And Earn FAQs'),
        defaultOpen: true,
        headerTextSize: 'h5-semibold md:h3-semibold',
        questionBank: referAndEarnquestionBank,
    },
];

export const addressProofs = [
    'Aadhar',
    'DrivingLicense',
    'VoterId',
    'Passport',
];

export const aiSafePreferenceBreadcrumbs = (value) => [
    {
        title: WalletName,
        url: '/dashboard/ai-safe',
    },
    {
        title: value,
        active: true,
    },
];

const investorsImageBaseUrl = '/images/homepage/investors';

export const companyInvestors = [
    {
        name: 'Siddharth Shah',
        position: 'Co-founder, Pharmeasy',
        image: `${investorsImageBaseUrl}/Siddharth Shah.png`,
    },
    {
        name: 'Dharmil Sheth',
        position: 'Co-founder, Pharmeasy',
        image: `${investorsImageBaseUrl}/Dharmil Sheth.png`,
    },
    {
        name: 'Ramakant Sharma',
        position: 'Co-founder, LivSpace',
        image: `${investorsImageBaseUrl}/Ramakant Sharma.png`,
    },
    {
        name: 'Mankekar',
        position: 'Family Office',
        image: `${investorsImageBaseUrl}/Mankekar.svg`,
    },
    {
        name: 'Capital - A',
        position: 'Venture Capital',
        image: `${investorsImageBaseUrl}/capital-A.svg`,
    },
];

const valuesImageBaseUrl = '/images/values';
export const CompanyValues = [
    {
        value: 'Customer obsession',
        description:
            'Deliver customer experience every day by bringing exclusive products and exceptional services.',
        image: `${valuesImageBaseUrl}/customerObsession.svg`,
    },
    {
        value: 'Collaborate',
        description:
            'We believe in establishing a culture of collaboration and inclusion, encouraging teamwork, openness, and entrepreneurship.',
        image: `${valuesImageBaseUrl}/collaborate.svg`,
    },
    {
        value: 'Excellence',
        description:
            'Our unwavering commitment to excellence is what enables us to consistently exceed the expectations of our customers, partners and that of our team.',
        image: `${valuesImageBaseUrl}/excellence.svg`,
    },
    {
        value: 'Integrity',
        description:
            'We are committed to doing the right thing and upholding the highest ethical standards with authenticity and transparency.',
        image: `${valuesImageBaseUrl}/integrity.svg`,
    },
    {
        value: 'Make a difference every day',
        description:
            'Our team strives to learn, adapt and improve every day to make a difference in our organization and in the communities we serve.',
        image: `${valuesImageBaseUrl}/puzzle.svg`,
    },
    {
        value: 'Ownership',
        description:
            'We take ownership and accountability for all tasks and actions undertaken and complete them on time.',
        image: `${valuesImageBaseUrl}/ownership.svg`,
    },
];

export const CompanyMilestones = [
    {
        month: 'Mar ‘22',
        transactionVolume: 250,// in crores
        registeredInvestors: 6000,
    },
    {
        month: 'Sep ‘22',
        transactionVolume: 560,
        registeredInvestors: 29000,
    },
    {
        month: 'Mar ‘23',
        transactionVolume: 1330,
        registeredInvestors: 75000,
    },
    {
        month: 'Sep ‘23',
        transactionVolume: 2400,
        registeredInvestors: 143000,
    },
    {
        month: 'Mar ‘24',
        transactionVolume: 3320,
        registeredInvestors: 186000,
    },
    {
        month: 'Sep ‘24',
        transactionVolume: 4180,
        registeredInvestors: 216000,
    },
];

export const getPhoneInputStyle = (width, disabled = false) => {
    return {
        containerStyle: {
            borderRadius: '8px',
            borderWidth: '1px',
            height: '48px',
            padding: '0px',
            borderColor: theme?.colors?.gray?.[200],
        },
        inputStyle: {
            width: '100%',
            height: '100%',
            borderWidth: '0px',
            paddingLeft: '4.5rem',
            backgroundColor: disabled ? theme?.colors?.gray?.[100] : theme?.colors?.basicWhite,
            borderRadius: '8px',
            fontWeight: '500',
            color: theme?.colors?.gray?.[900],
        },
        buttonStyle: {
            borderWidth: '0px',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            width: '4rem',
        },
        dropdownStyle: {
            overflow: 'auto',
            height: '185px',
            width: width <= 480 ? (width <= 374 ? '178px' : '233px') : '300px',
        },
    };
};

export const getMobileNumberLength = (code) => {
    switch (code) {
        case '971':
            return 9;
        case '65':
            return 8;
        case '61':
            return 9;
        case '974':
            return 8;
        case '966':
            return 9;
        case '49':
            return [11, 12];
        case '33':
            return 9;
        case '60':
            return 9;
        default:
            return 10;
    }
};

export const getOpportunityTypeBadgeColor = (typeValue) => {
    switch (typeValue) {
        case OpportunityTypes.CORPORATE_DEBT:
            return 'bg-pink-100 border-none';
        case OpportunityTypes.TREASURY_BILL:
            return 'bg-skin-100  border-none';
        case OpportunityTypes.VENTURE_DEBT:
            return 'bg-skyBlue-100  border-none';
        case OpportunityTypes.ASSET_BACKED_LEASING:
            return 'bg-steelBlue-100  border-none';
        case OpportunityTypes.INVOICE_DISCOUNTING:
            return 'bg-mauve-100  border-none';
        default:
            return 'bg-skyBlue-100  border-none';
    }
};

export const getReinvestBadgeColor = () => 'bg-special-gold border border-special-gold'

export const productStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    name: `Products | ${brandName}`,
    itemListElement: [
        {
            '@type': 'ListItem',
            position: 1,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/products/unlisted-corporate-debt`,
                name: 'Corporate Debt (Unlisted)',
            },
        },
        {
            '@type': 'ListItem',
            position: 2,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/products/venture-debt`,
                name: 'Venture Debt',
            },
        },
        {
            '@type': 'ListItem',
            position: 3,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/products/invoice-discounting`,
                name: 'Invoice Discounting',
            },
        },
        {
            '@type': 'ListItem',
            position: 4,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/products/asset-backed-leasing`,
                name: 'Asset Backed Leasing',
            },
        },
    ],
};

export const aboutUsStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    name: `About Us | ${brandName}`,
    url: `${process.env.NEXT_PUBLIC_APP_URL}/about-us`,
    itemListElement: [
        {
            '@type': 'ListItem',
            position: 1,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/about-us`,
                name: 'Our Company',
            },
        },
        {
            '@type': 'ListItem',
            position: 2,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/about-us#milestones`,
                name: 'Our Milestones',
            },
        },
        {
            '@type': 'ListItem',
            position: 3,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/about-us#values`,
                name: 'Our Values',
            },
        },
        {
            '@type': 'ListItem',
            position: 4,
            item: {
                '@id': `${process.env.NEXT_PUBLIC_APP_URL}/about-us#investors`,
                name: 'Investors',
            },
        },
        // hidden on altgraaf
        // {
        //     '@type': 'ListItem',
        //     position: 5,
        //     item: {
        //         '@id': '${process.env.NEXT_PUBLIC_APP_URL}/team',
        //         name: 'Team',
        //     },
        // },
    ],
};

export const careerStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: `Careers | ${brandName}`,
    url: `${process.env.NEXT_PUBLIC_APP_URL}/careers`,
};

export const contactUsStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: `Contact Us | ${brandName}`,
    url: `${process.env.NEXT_PUBLIC_APP_URL}/contact-us`,
    founders: [
        {
            '@type': 'Person',
            name: 'Saurav Ghosh',
        },
        {
            '@type': 'Person',
            name: 'Vineet Agrawal',
        },
    ],
    address: {
        '@type': 'PostalAddress',
        streetAddress:
            '86/1, 2nd Floor Maruti Complex',
        addressLocality: 'New Thippasandra Main Road, Next To Anjaneya Temple',
        addressRegion: 'Bengaluru',
        postalCode: '560075',
        addressCountry: 'INDIA',
    },
    contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer support',
        telephone: '+91-9711321050',
        email: supportEmail,
    },
    sameAs: [
        'https://twitter.com/altgraaf',
        'https://www.linkedin.com/company/altgraafofficial/',
        'https://www.instagram.com/altgraaf_official/',
        'https://www.facebook.com/altGraaf.Official',
    ],
};

export const disclaimerStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: `Disclaimer | ${brandName}`,
    url: `${process.env.NEXT_PUBLIC_APP_URL}/disclaimer`,
};

export const faqStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'QAPage',
    name: `Frequently Asked Questions | ${brandName}`,
    url: `${process.env.NEXT_PUBLIC_APP_URL}/faq`,
};

export const homePageStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: `${brandName}`,
    url: `${process.env.NEXT_PUBLIC_APP_URL}`,
};

// Hidden on altgraaf
// export const mediaStructuredData = {
//     '@context': 'https://schema.org',
//     '@type': 'NewsArticle',
//     name: `In the News | ${brandName}`,
//     url: `${process.env.NEXT_PUBLIC_APP_URL}/media`,
// };

export const privacyPolicyStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: `Privacy Policy | ${brandName}`,
    url: `${process.env.NEXT_PUBLIC_APP_URL}/privacy-policy`,
};

export const riskDisclosureStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: `Risk Disclosure | ${brandName}`,
    url: `${process.env.NEXT_PUBLIC_APP_URL}/risk-disclosure`,
};

// Hidden on altgraaf
// export const teamStructuredData = {
//     '@context': 'https://schema.org',
//     '@type': 'WebPage',
//     name: `Team | ${brandName}`,
//     url: `${process.env.NEXT_PUBLIC_APP_URL}/team`,
// };

export const termAndConditionStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: `Terms and Conditions | ${brandName}`,
    url: `${process.env.NEXT_PUBLIC_APP_URL}/terms-and-condition`,
};

// Hidden on altgraaf
// export const webibarStructuredData = {
//     '@context': 'https://schema.org',
//     '@type': 'Event',
//     eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
//     name: `Webinars | ${brandName}`,
//     url: `${process.env.NEXT_PUBLIC_APP_URL}/webinars`,
// };

export const homepageVideoUrl =
    'https://team-members-jiraaf.s3.ap-south-1.amazonaws.com/Jiraafwithoutmilestone02.02.2023.mp4';

export const RecentlyClosedOpportunityFilters = [
    {
        label: 'Counter party',
        name: 'counterParties',
    },
    {
        label: 'Asset Class',
        name: 'type',
    },
    {
        label: 'Sector',
        name: 'industries',
    },
    {
        label: 'Status',
        name: 'state',
    },
    {
        label: 'RiskCategory',
        name: 'riskCategory',
    },
];

export const assetClassOptions = {
    [OpportunityHelper.getInvoiceDiscountingOpportunity()]: 'Invoice Discounting',
//     [OpportunityHelper.getCorporateDebtOpportunity()]: 'Corporate Debt (unlisted)',
//     [OpportunityHelper.getTreasuryBillOpportunity()]: 'Treasury Bills',
//     [OpportunityHelper.getVentureDebtOpportunity()]: 'Venture Debt',
//     [OpportunityHelper.getAssetBackedLeasingOpportunity()]: 'Asset Backed Leasing',
};

export const riskRatingOptions = {
    VeryLow: 'Very Low',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High',
    VeryHigh: 'Very High',
};

export const resultClosedDealsLimit = 6;

export const assetClassDetails = {
    [OpportunityHelper.getInvoiceDiscountingOpportunity()]: {
        tenure: '1 - 6 months',
        irr: '10% - 14%',
    },
    [OpportunityHelper.getCorporateDebtOpportunity()]: {
        tenure: '12 - 36 months',
        irr: '10% - 16%',
    },
    [OpportunityHelper.getVentureDebtOpportunity()]: {
        tenure: '12 - 24 months',
        irr: '15% - 18%',
    },
    [OpportunityHelper.getTreasuryBillOpportunity()]: {
        tenure: '91 - 364 days',
        irr: '6% - 7%',
    },
    [OpportunityHelper.getAssetBackedLeasingOpportunity()]: {
        tenure: '24 - 48 months',
        irr: '14% - 22%',
    },
};

export const closedFilterKeyValuesMap = {
    ...assetClassOptions,
    ...riskRatingOptions,
    OngoingRepayment: 'Ongoing - Ongoing Repayment',
    MaturedAndClosed: 'Matured - Matured & Closed',
    PrepaidAndClosed: 'Matured - Prepaid & Closed',
};

export const ifaMasterCommisionPercentage = 1;

export const borrowerFollowRecords = [
    {
        first: 'Restrictions on repayment of other loans or corporate guarantees, until repaid to altGraaf.',
        second: 'Maintaining certain parameters such as revenue, costs, EBITDA, cash burn etc.',
        align: 'around',
        image: '',
    },
    {
        first: 'Adherence to sufficient funds in reserve account for servicing debt',
        second: 'Restrictions on change in capital structure/management, shareholding pattern, etc.',
        image: '/images/shield.svg',
        align: 'between',
    },
    {
        first: 'Preserving credit rating of the company and maintaining pre-determined debt-equity ratio',
        second: 'No fundamental change to company such as Name, Registered Office, Authorised Capital',
        image: '',
        align: 'around',
    },
];

export const CreditAssessmentLayers = [
    {
        step: '01',
        text: 'Pre-sanction Credit Risk Assessment',
        link: '/credit-evaluation-process/#pre-sanction-risk-assessment',
    },
    {
        step: '02',
        text: (
            <>
                Deal <br className="md:block hidden" /> Structuring
            </>
        ),
        link: '/credit-evaluation-process/#deal-structuring',
    },
    {
        step: '03',
        text: 'Post Sanction Monitoring',
        link: '/credit-evaluation-process/#post-sanction-monitoring',
    },
];

export const preSanctionRiskAssessmentData = [
    {
        title: 'Assessing Credibility',
        descHead: '',
        desc: [
            'Assessment of management credibility',
            'Adherence to legal, regulatory and statutory guidelines',
            'Review of past conduct with lenders',
            'Third-party verification',
            'Corporate governance concerns',
        ],
        image: '/images/CreditEvaluationProcess/AccessCredibility.svg',
        className: 'h-[72px] w-[72px] mr-auto xl:m-auto',
    },
    {
        title: 'Business Model Validation',
        descHead: 'Key factors analysed:',
        desc: [
            'Product and market evaluation',
            'Competitive analysis',
            'Financial metrics and margins',
            'Business risks',
        ],
        image: '/images/CreditEvaluationProcess/BusinessModelVerification.svg',
        className: 'h-[72px] w-[72px] mr-auto xl:m-auto',
    },
    {
        title: 'Ability to Repay on Time',
        descHead: '',
        desc: [
            'Sensitivity analysis and stress test of operating cash flows',
            'Ability to infuse and raise money',
            'Verification of cash reserves for working capital needs, debt obligations and equity fund flows',
        ],
        image: '/images/CreditEvaluationProcess/AbilityToRepay.svg',
        className: 'h-[72px] w-[72px] mr-auto xl:m-auto',
    },
];

// country codes for phoneInput
export const userCountryCodesInput = [
    'in',
    'ca',
    'us',
    'gb',
    'ae',
    'sg',
    'au',
    'fr',
    'de',
    'my',
    'qa',
    'sa',
];

// mobile number length check
export const isValidMobileNumber = (value, country) => {
    if (value.length > country?.countryCode.length) {
        const mobileLength = getMobileNumberLength(country?.countryCode);
        if (typeof mobileLength === 'object') {
            // range length
            const digits = value.length - country.countryCode.length;
            if (
                mobileLength?.length >= 2 &&
                digits >= Number(mobileLength[0]) &&
                digits <= Number(mobileLength[1])
            ) {
                return true;
            }
        } else if (value.length - country.countryCode.length === mobileLength) {
            return true;
        }
    }
    return false;
};

// get country of origin from country code
export const getCountryOfOrigin = (countryCode) => {
    let countryOfOrigin;
    switch (countryCode) {
        case '+971':
            countryOfOrigin = 'UAE';
            break;
        case '+1':
            countryOfOrigin = 'US/Canada';
            break;
        case '+44':
            countryOfOrigin = 'UK';
            break;
        case '+65':
            countryOfOrigin = 'Singapore';
            break;
        case '+61':
            countryOfOrigin = 'Australia';
            break;
        case '+33':
            countryOfOrigin = 'France';
            break;
        case '+49':
            countryOfOrigin = 'Germany';
            break;
        case '+60':
            countryOfOrigin = 'Malaysia';
            break;
        case '+974':
            countryOfOrigin = 'Qatar';
            break;
        case '+966':
            countryOfOrigin = 'Saudi Arabia';
            break;
        default:
            countryOfOrigin = 'India';
            break;
    }
    return countryOfOrigin;
};

// custom styles react-select
export const customStylesSelect = {
    // For the select it self, not the options of the select
    control: (styles, { isDisabled }) => {
        return {
            ...styles,
            color: isDisabled ? 'white' : theme?.colors?.gray?.[700],
            backgroundColor: isDisabled
                ? theme?.colors?.['gray-light-shade']
                : 'white',
            minHeight: '44px',
            cursor: 'pointer',
            fontSize: '14px',
            borderRadius: '6px',
            fontWeight: 500,
            textTransform: 'capitalize',
        };
    },
    indicatorSeparator: () => ({
        display: 'none',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: theme?.colors?.gray?.[700],
        textTransform: 'capitalize',
        border: 0,
        fontWeight: 500,
        fontSize: '14px',
    }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            textTransform: 'capitalize',
            fontSize: '14px',
            backgroundColor: isFocused
                ? theme.colors.primary[500]
                : theme.colors.basicWhite,
            color: isFocused
                ? theme.colors.basicWhite
                : theme.colors.basicBlack,
        };
    },
    placeholder(base, state) {
        return {
            ...base,
            display: state.isFocused && 'none',
        };
    },
    dropdownIndicator: (base, { selectProps: { menuIsOpen } }) => ({
        ...base,
        transition: 'all .4s ease',
        transform: menuIsOpen
            ? 'rotate(180deg)'
            : null,
    }),
};

export const customStylesSelectUI = {
    ...customStylesSelect,
    control: (styles, { isDisabled }) => {
        return {
            ...customStylesSelect.control(styles, { isDisabled }),
            borderRadius: '8px',
            fontFamily: 'GeneralSans, sans-serif',
            boxShadow: '0 !important',
            borderWidth: '1px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderColor: theme?.colors?.gray?.[200],
            '&:hover': {
                borderColor: theme?.colors?.gray?.[200],
            },
        };
    },
    singleValue: (provided) => ({
        ...customStylesSelect.singleValue(provided),
        color: theme?.colors?.gray?.[900],
        fontSize: '16px',
        fontWeight: 400,
    }),
    placeholder(base, state) {
        return {
            ...base,
        };
    },
};

export const CommonSelectStyleUI = (
    width,
    dropdownHeight = '58px',
    borderWidth = '1.5px',
    grayValue = 300,
    valueFontSize = width >= 768 ? '16px' : '14px',
    placeholderColor = theme?.colors?.gray?.[400],
    error = false,
    textTransform = 'capitalize',
    optionsborderRadius = null,
    menuborderRadius = null,
    valueFontWeight = '500'
) => {
    return {
        ...customStylesSelectUI,
        control: (styles, { isDisabled, isFocused }) => {
            return {
                ...customStylesSelectUI.control(styles, { isDisabled }),
                borderWidth: borderWidth,
                borderColor: isFocused
                    ? theme?.colors?.primary?.[500]
                    : error ? theme?.colors?.semantic?.['error-base'] : theme?.colors?.gray?.[grayValue],
                height: width >= 768 ? dropdownHeight : '44px',
                borderRadius: '8px',
                '&:hover': {
                    outline: 'none',
                },
                textTransform: textTransform
            };
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...customStylesSelectUI.option(styles, { data, isDisabled, isFocused, isSelected }),
                textTransform: textTransform,
                borderRadius: optionsborderRadius || '0px',
            };
        },
        singleValue: (provided) => ({
            ...customStylesSelectUI.singleValue(provided),
            fontSize: valueFontSize,
            fontWeight: valueFontWeight,
            textTransform: textTransform
        }),
        placeholder(base, state) {
            return {
                ...customStylesSelectUI.placeholder(base, state),
                fontWeight: '400',
                color: placeholderColor,
                fontSize: valueFontSize,
            };
        },
        menu: (styles) => {
            return {
                ...styles,
                borderRadius: menuborderRadius || '4px',
            };
        },
    };
};

export const opportunityPreferenceData = [
    {
        name: 'All investors',
        type: 'AllInvestor',
    },
    {
        name: 'Select a sub-set of investors',
        type: 'SelectSet',
        preferences: [
            {
                name: `Source`,
                type: 'source',
                isDropDown: true,
                preferences: [
                    {
                        label: `IFA`,
                        value: 'IFAOnboardedInvestor',
                    },
                    {
                        label: 'D2C Investors',
                        value: 'D2CInvestor',
                    },
                ],
            },
            {
                name: 'First Time Investors',
                type: 'OnlyForFirstTimeInvestor',
            },
            {
                name: `Current AUM is 0`,
                type: 'ZeroAUMInvestor',
            },
        ],
    },
];

export const adminPaths = ['/admin', '/adminLogin'];
export const opportunityPreferencesArray = [
    'AllInvestor',
    'IFAOnboardedInvestor',
    'OnlyForFirstTimeInvestor',
    'ZeroAUMInvestor',
    'D2CInvestor'
];

export const opportunityPreferenceSet = [
    'IFAOnboardedInvestor',
    'OnlyForFirstTimeInvestor',
    'ZeroAUMInvestor',
    'D2CInvestor'
];

export const opportunityPreferenceSource = [
    'IFAOnboardedInvestor',
    'D2CInvestor'
];

export const consentErrorMessage = {
    "Investment hasn't signed the Source of Funds Declaration.": `You have missed accepting the "Source of Funds Declaration", please restart investment.`,
    "Investment hasn't signed the Demat Declaration.": `You have missed accepting the " Demat Declaration", please restart investment.`,
    "Investment doesn't have a linked signed investor transaction document.": `You have missed signing the "Transaction Document", please restart investment.`,
};

export const getClosedOpportunitiesCacheTime = () => {
    return process.env.NEXT_PUBLIC_CLOSED_OPPORTUNITIES_CACHE_TIME;
};

export const getCacheTime = () => {
    return process.env.NEXT_PUBLIC_CACHE_DURATION;
};

export const opportunityStatesFilterOptions = [
    {
        label: 'All',
        value: 'OngoingRepayment,PrepaidAndClosed,MaturedAndClosed',
    },
    {
        label: 'Ongoing',
        value: 'OngoingRepayment',
    },
    {
        label: 'Matured',
        value: 'MaturedAndClosed,PrepaidAndClosed',
    },
];

export const kycRequirement = {
    nri: {
        id: 1,
        type: 'nri',
        steps: ['PAN Number', 'Indian Passport', 'Bank account Details'],
    },
    individual: {
        id: 2,
        type: 'individual',
        steps: [
            'PAN Number',
            'Address proof (Aadhaar/Passport/Voter ID)',
            'Bank Account Details',
        ],
    },
};

export const accountTypes = [
    {
        id: 1,
        type: 'individual',
        title: 'Individual',
        description: 'Resident of india',
        imgSrc: 'individual.svg',
        buttonText: 'Continue as Individual',
    },
    {
        id: 2,
        type: 'nri',
        title: 'Non Resident Indian (NRI)',
        description: 'Indian citizen, residing abroad',
        imgSrc: 'nri.svg',
        buttonText: 'Continue as NRI',
    },
];

export const nomineeRelationOptions = [
    { label: 'Father', value: 'Father' },
    { label: 'Mother', value: 'Mother' },
    { label: 'Son', value: 'Son' },
    { label: 'Daughter', value: 'Daughter' },
    { label: 'Spouse', value: 'Spouse' },
    { label: 'Brother', value: 'Brother' },
    { label: 'Sister', value: 'Sister' },
    { label: 'Other', value: 'Other' },
];

export const ReportDownloadingMessage =
    'Please wait we are collecting all the download. The process might take longer.';

export const highlightedHeading = (text) => {
    return (
        <>
            {text}
            <div className="w-10 h-[2px] mt-1 bg-secondary-500"></div>
        </>
    );
};

export const kycSteps = [
    {
        image: 'pan',
        step: 1,
        title: 'PAN details',
    },
    {
        image: 'address',
        step: 2,
        title: 'Upload address proof',
    },
    {
        image: 'bank',
        step: 3,
        title: 'Enter bank details',
    },
];

export const NriDisclaimerPoints = [
    `This website is for the exclusive purpose of transactions to be carried out within the territorial jurisdiction of India and all such transactions shall be governed by the laws in India including but not limited to enforce foreign exchange management norms and regulatory regime looked after by the concerned ministry(ies) and department(s) of the Government of India along with Reserve Bank of India. Notice is hereby given that Non Resident Indians (NRI's) accessing this website and opting to transact hereon shall do so after due verification at your end of your eligibility to do so. We undertake no responsibility for such pre-eligibility of qualification on part of Non-Resident Indians (NRI's) to transact on this website.`,
    `You may avail our product/services on an unsolicited basis but should consult your tax / financial advisor regarding any statutory reporting requirements or tax liabilities you might be subject to. We do not provide tax advice. Apart from prevailing tax liabilities in India, there may be tax liabilities in your host country when you repatriate funds back to your host country.`,
];

export const AddressProofTypesForNri = ['Passport', 'VoterId', 'Aadhar'];

export const imageFileTypes = ['jpeg', 'jpg', 'png', 'tiff'];

export const accountTypesOptions = [
    {
        label: 'Individual',
        value: 'Individual',
    },
    {
        label: 'NRI',
        value: 'Nri',
    },
];

export const NriTermsAndConditions = [
    "This website is for the exclusive purpose of transactions to be carried out within the territorial jurisdiction of India and all such transactions shall be governed by the laws in India including but not limited to enforce foreign exchange management norms and regulatory regime looked after by the concerned ministry(ies) and department(s) of the Government of India along with Reserve Bank of India. Notice is hereby given that Non Resident Indians (NRI's) accessing this website and opting to transact hereon shall do so after due verification at your end of your eligibility to do so. We undertake no responsibility for such pre-eligibility of qualification on part of Non-Resident Indians (NRI's) to transact on this website.",
    'You may avail our product/services on an unsolicited basis but should consult your tax / financial advisor regarding any statutory reporting requirements or tax liabilities you might be subject to. We do not provide tax advice. Apart from prevailing tax liabilities in India, there may be tax liabilities in your host country when you repatriate funds back to your host country.',
];

export const SupoortContact = '+91 9711321050';

/*
 * using this for htmlContent fields
 * font-bold -> font-semibold
 * <b> -> ''
 * </b> -> ''
 * <th class="border-r-2 px-4 py-2 -> <th class="border-r-2 px-4 py-2 font-medium
 *
 * will remove this in future when we will migrate the html data
 */
export const keyContentReplacedPairs = [
    ['font-bold', 'font-medium'],
    ['<b[^>]*>', ''],
    ['</b>', ''],
    [
        '<th class="border-r-2 px-4 py-2',
        '<th class="border-r-2 px-4 py-2 font-medium',
    ],
];

export const grievanceEmail = 'grievance@altgraaf.com';

export const recentlyClosedOpportunityStages = [
    {
        InprogesLabel: 'Fully Subscribed',
        completedLabel: 'Fully Subscribed',
        id: 1,
    },
    {
        InprogesLabel: 'Earning Returns',
        completedLabel: 'Returns Earned',
        id: 2,
    },
    {
        InprogesLabel: 'Fully Repaid',
        completedLabel: 'Fully Repaid',
        id: 3,
    },
];

export const festiveGifPages = [
    { page: '/opportunities', display: 'block' },
    { page: '/dashboard', display: 'lg:block hidden' },
    { page: '/', display: 'block' },
    { page: '/about-us', display: 'block' },
    { page: '/team', display: 'block' },
    { page: '/credit-evaluation-process', display: 'block' },
    { page: '/blogs', display: 'block' },
    { page: '/media', display: 'block' },
    { page: '/faq', display: 'block' },
    { page: '/webinars', display: 'block' },
    { page: '/products/[slug]', display: 'block' },
    { page: '/contact-us', display: 'block' },
    { page: '/terms-and-condition', display: 'block' },
    { page: '/privacy-policy', display: 'block' },
    { page: '/risk-disclosure', display: 'block' },
];

export const altgraafLaunchDate = '2023-11-26T18:30:00.000Z';

export const envsEnum = Object.freeze({
    PRODUCTION: "production",
    DEVELOPMENT: "development",
    QA: "qa",
    QASLAVE: "qaslave",
    QAMOBILE: "qamobile",
    UAT: "uat",
    UATMOBILE: "uatmobile"
});

export const keyPerformanceMatricsStaticHeadingLiveDate = process.env.NEXT_PUBLIC_CURRENT_ENV === envsEnum.PRODUCTION ? '2024-05-13T18:30:00.000Z' : '2024-05-07T18:30:00.000Z';

export const investmentTimeFrameOption = [
    { label: 'In the next few weeks', value: 'FewWeeks' },
    { label: 'In the next few months', value: 'FewMonths' },
    { label: 'Not planning to invest', value: 'NotPlanning' },
];

export const isFestivekandilVisible = false;

export const unAuthorized = 'unAuthorized';

export const appDashboardLink = 'https://altgraaf.page.link/w13Z';

export const appDownloadLink = 'https://altgraaf.page.link/9cL2';

export const DEFAULT_TAGS_SIZE = 4;

export const DematStatus = Object.freeze({
    PENDING: 'Pending',
    VERIFIED: 'Verified',
    REJECTED: 'Rejected',
});

export const webinarBannerHighlights = [
    {
        id: 1,
        title: 'Expert Insights',
        description: 'Learn from industry leaders and seasoned investors',
        image: '/images/webinars/expert-insights.svg',
    },
    {
        id: 2,
        title: 'Interactive Sessions',
        description: 'Get your questions answered live during the Q&A',
        image: '/images/webinars/interactive-sessions.svg',
    },
    {
        id: 3,
        title: 'On-Demand Access',
        description: 'View past webinars at your convenience without any sign-up',
        image: '/images/webinars/on-demand-access.svg',
    },
];

export const investmentFallbackStats = {
    investedAmount: 3600,
    repaidAmount: 3100,
    averageReturns: 12.5,
    defaultPercentage: 0,
    companiesOnboarded: 69,
    totalDealsOriginated: 1609,
    totalDealsRepaid: 1346,
    registeredUsers: 2,
};

export const opportunitiesViews = {
    LIST: 'list',
    CARD: 'card'
}

export const WEBINARS_PAGE_LIMIT = 10;

export const christmasFestiveSeasonConfig = 'v2';

export const minWalletAmountToShowAsFlag = 100000;

export const appDeeplinkPrefixUrl = 'https://altgraaf.page.link';

export const openMobileSideNavigation = {};

export const HOMEPAGE_BANNER_CAROUSEL_AUTOPLAY_INTERVAL = 3000;

export const addressProofMaxFileSize = 4096;

export const panMaxLength = 10;

export const dematMaxLengthWithHyphen = 19;

export const ifscMaxLength = 11;

export const isNewsLetterPageActive = false;