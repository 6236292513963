import { getAuth } from "@services/identity.service";

export const loadDigioSdk = () => {
    const auth = getAuth();
    if(!auth) return;
    const isProduction = ["production", "uat"].includes(process.env.NODE_ENV)
    const scriptId = "digio-sdk";
    // If the script is already loaded, do nothing
    if (document.getElementById(scriptId)) return;

    const script = document.createElement("script");
    script.id = scriptId;
    script.defer = true;
    script.type = "text/javascript";
    script.src = isProduction ? "https://app.digio.in/sdk/v11/digio.js" : "https://ext.digio.in/sdk/v11/digio.js";
    document.body.appendChild(script);
};