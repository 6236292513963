// utils/gtm.js
export const initializeGTM = (gtmId) => {
    if (!gtmId || gtmId === 'GTM-dummy') return;

    const scriptContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');`;

    // Inject the GTM script into the document head
    const scriptElement = document.createElement('script');
    scriptElement.defer = true;
    scriptElement.textContent = scriptContent;
    document.head.appendChild(scriptElement);
};