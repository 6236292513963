export const loadFreshChat = (is_web_view) => {
    if (process.env.NEXT_PUBLIC_FRESHCHAT_WIdGET_ID !== 'FC-dummy' && typeof window !== 'undefined' && is_web_view !== 'true') {
        // Load jQuery (FreshChat depends on it)
        const loadJQuery = document.createElement('script');
        loadJQuery.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
        loadJQuery.defer = true;
        document.body.appendChild(loadJQuery);

        // Load FreshChat script after jQuery is loaded
        loadJQuery.onload = () => {
            const freshChatScript = document.createElement('script');
            freshChatScript.src = '//in.fw-cdn.com/30185002/117542.js';
            freshChatScript.defer = true;
            freshChatScript.setAttribute('chat', 'true');
            freshChatScript.setAttribute('widgetId', process.env.NEXT_PUBLIC_FRESHCHAT_WIdGET_ID);
            document.body.appendChild(freshChatScript);
        };
    }
};