/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
const plugin = require('tailwindcss/plugin');
const defaultTailwindColors = require('./jiraaf-ui/helpers/tailwind-default-colors');
const defaultTheme = require('tailwindcss/defaultTheme');
const colorConstants = require('./jiraaf-ui/helpers/color-constants');
const { default: typography } = require('./jiraaf-ui/helpers/typography');
const backgroundConstants = require('./helpers/tailwind/backgroundConstants');
const shadows = require('./helpers/tailwind/shadows');

module.exports = {
    content: [
        './pages/*.js',
        './pages/**/*.js',
        './pages/**/**/*.js',
        './pages/**/**/**/*.js',
        './pages/**/**/**/**/*.js',
        './atoms/*.js',
        './molecules/*.js',
        './molecules/**/*.js',
        './organisms/*.js',
        './organisms/**/*.js',
        './templates/*.js',
        './templates/**/*.js',
        './components/**/*.{js,jsx}',
        './features/**/*.{js,jsx}',
        './components/**/**/*.{js,jsx}',
        './components/**/**/**/*.{js,jsx}',
        './components/**/**/**/**/*.{js,jsx}',
        './jiraaf-ui/**/*.{js,jsx}',
    ],
    options: {
        whitelist: ['.grid', '.grid-cols-3', '.gap-x-5'],
        safelist: [
            {
                variants: ['text-5xl', 'sm', 'lg', 'xl', 'md', '.w-1010'],
            },
        ],
    },
    theme: {
        letterSpacing: {
            ...defaultTheme.letterSpacing,
        },
        colors: {
            /* IMPORTANT: New Colors from design system should be 
            added to jiraaf-ui/helpers/color-constants.js */

            ...defaultTailwindColors, // used in admin
            ...colorConstants,
            gray: {
                ...colorConstants.gray,
                950: '#707683', // used in admin
            },
            yellow: {
                ...colorConstants.yellow,
                300: '#FDE047', // used in admin
                400: '#FACC15', // used in admin
                600: '#CA8A04', // used in admin
            },
            green: {
                ...colorConstants.green,
                500: '#22C55E', // used in admin
            },
            darkBlack: '#222428', //used in admin
            'gray-medium-shade': '#808289', // used in admin
            'gray-light-shade': '#D7D8DA', // used in admin
            'athens-gray': '#E7E9F0', // used in Admin
        },
        extend: {
            gridTemplateColumns: {
                13: 'repeat(13,minmax(0,1fr))',
                14: 'repeat(14,minmax(0,1fr))',
                16: 'repeat(16,minmax(0,1fr))',
                18: 'repeat(18,minmax(0,1fr))',
                20: 'repeat(20,minmax(0,1fr))',
                21: 'repeat(21,minmax(0,1fr))',
                'payment-schedule-popup-ncd':
                    'minmax(10.5rem, 1.5fr) repeat(3,minmax(0, 1.5fr))',
            },
            keyframes: {
                fade: {
                    from: {
                        opacity: 0,
                        transform: 'translate3d(0, -1rem, 0)',
                    },
                    to: {
                        opacity: 1,
                        transform: 'translate3d(0,0, 0)',
                    },
                },
                'slow-bounce': {
                    '0%, 100%': { transform: 'translateY(-25%)' },
                    '50%': { transform: 'none' },
                },
            },
            animation: {
                'fade-in': 'fade 500ms linear ',
                'slow-bounce': 'slow-bounce 1.5s infinite;',
            },

            lineHeight: {
                tighter: 1.15,
                32: '8rem',
            },
            screens: {
                mobile: { min: '360px', max: '768px' },
                xs: { min: '425px', max: '640px' },
                'my-investments-table': { min: '1095px' },
                'desktop-md': '1440px',
                'h-medium': { raw: '(max-height: 800px)' },
                'h-small': { raw: '(max-height: 700px)' },
                'h-x-small': { raw: '(max-height: 600px)' },
                maxScreen: '1440px',
                'xs-400': '400px',
                ...defaultTheme.screens,
            },
            zIndex: {
                '-1': '-1',
                100: '100',
            },
            listStyleType: {
                roman: 'lower-roman',
                alpha: 'lower-alpha',
            },
            grayscale: {
                100: '100%',
            },
            dropShadow: {
                xl: '0 20px 13px rgba(117, 117, 247, 0.2)',

                '3xl': '0 45px 45px rgba(117, 117, 247, 0.2)',
            },
            inset: (theme) => ({
                '1/5': '20%',
                '1/10': '10%',
                '1/20': '4.3%',
                10.5: '2.625rem',
                8.5: '2.125rem',
                24.5: '6.3rem',
                200: '50rem',
                header: 'var(--header-height)',
                'header-mobile': 'var(--header-height-mobile)',
            }),
            margin: (theme) => ({
                22: '5.5rem',
                15: '3.75rem',
                13: '2.188rem',
                15.5: '4.688rem',
                header: 'var(--header-height)',
                'header-mobile': 'var(--header-height-mobile)',
            }),
            padding: (theme) => ({
                'screen-90': '80vh',
                'screen/1.3': '75vh',
                'screen/2': '50vh',
                'screen/3': 'calc(100vh / 3)',
                'screen/4': 'calc(100vh / 4)',
                'screen/5': 'calc(100vh / 5)',
                4.5: '1.125rem',
                '52px': '3.25rem',
                18: '4.5rem',
                53: '13.25rem',
                22: '5.5rem',
                '18px': '1.125rem',
                '34px': '2.125rem',
                17: '4.25rem',
                19: '4.75rem',
                26: '6.5rem',
                14: '3.625rem',
                34: '8.5rem',
                46: '11.813rem',
                62: '15.875rem',
                18.5: '4.625rem',
                114.5: '28.625rem',
                header: 'var(--header-height)',
                'header-mobile': 'var(--header-height-mobile)',
            }),
            height: (theme) => ({
                18: '4.5rem',
                75: '18.75rem',
                200: '50rem',
                127: '508px',
                138: '552px',
                121: '30.25rem',
                107: '26.75rem',
                '95%': '95%',
                85: '21.25rem',
                85: '21.25rem',
                85: '21.25rem',
                '92vh': '92vh',
            }),
            borderWidth: (theme) => ({
                1: '1px',
                1.5: '1.5px',
            }),
            borderRadius: (theme) => ({
                '10px': '0.625rem',
                '7px': '0.438rem',
                '20px': '1.125rem',
                '32px': '2rem',
                '40px': '2.5rem',
                '80px': '5rem',
            }),
            maxWidth: {
                60: '12rem',
                70: '17.5rem',
                316: '79rem',
                745: '745px',
                1024: '1024px',
            },
            boxShadow: shadows,
            fontFamily: {
                paragraph: ['GeneralSans', 'sans-serif'],
                heading: ['GeneralSans', 'sans-serif'],
            },
            spacing: {
                360: '5.625rem',
                122: '30.625rem',
            },
            fontWeight: (theme) => ({
                normal: '400',
                aboveNormal: '450',
            }),
            content: {
                rupeeSymbol: '₹',
            },
            fontSize: (theme) => ({
                'title:size': [33, 36],
                '10px': ['0.65rem'],
                '18px': '0.938rem',
                '28px': '1.75rem',
                '32px': '2rem',
                '40px': ['2.5rem', 1.5],
                '47px': ['47px', '60px'],
                '77px': ['70px', '78px'],
            }),
            container: {
                padding: {
                    xl: '2rem',
                },
            },
            width: {
                18: '4.5rem',
                250: '1000',
                333: '83rem',
                120: '30rem',
                140: '35rem',
                316: '79rem',
                334: '20.875rem',
                748: '46.75rem',
                1010: '63.125rem',
            },
            minWidth: {
                50: '12.5rem',
                55: '13.75rem',
                85.75: '21.438rem',
                150: '37.5rem',
            },
            minHeight: {
                12: '3rem',
                25: '6.25rem',
                400: '400px',
            },
            maxHeight: {
                section: '80vh',
            },
            backgroundImage: backgroundConstants,
            backgroundPosition: {
                'right-50': '50%',
                'right-68': '68%',
            },
            backgroundSize: {
                '135%': '135%',
                '160%': '160%',
                '300%': '300%',
            },
        },
    },
    plugins: [
        require('tailwindcss-rtl'),
        require('@tailwindcss/forms'),
        plugin(({ addUtilities }) => {
            addUtilities(typography);
        }),
    ],
};
