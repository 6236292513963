/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
//import '@ui/styles/globals.css';
import '@styles/globals.css';
import '@styles/Homepage/style.css';
import '@styles/Products/style.css';
import { getLayoutConfig } from '@components/Layout/LayoutConfiguration';
import dynamic from 'next/dynamic';
import { adminPaths } from '@helpers/constants';
import Aos from 'aos';
import { useEffect } from 'react';
const ToastContainer = dynamic(() =>
    import('react-toastify').then((mod) => mod.ToastContainer)
);
const InvestorContextProvider = dynamic(() =>
    import('@context/InvestorContextProvider')
);
const WithAuth = dynamic(() => import('@components/Auth/WithAuth'), {
    ssr: true,
    csr: true,
});
import { initializeGTM } from 'lib/gtm';
import { loadFreshChat } from '@lib/load-fresh-chat';
import { loadDigioSdk } from '@lib/load-digio-sdk';

const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
function MyApp({ Component, pageProps, router }) {
    let layoutConfig = getLayoutConfig(router.pathname, router.query);
    const { Layout: FetchedLayout, overrides } = layoutConfig;

    useEffect(() => {
        Aos.init({ duration: 800, once: false });
        initializeGTM(GTM_ID);
        loadDigioSdk();
        if(router.pathname.includes("/signup")){
            setTimeout(() => {
                loadFreshChat(router.query?.is_web_view);
            }, 3000);
        }else{
            loadFreshChat(router.query?.is_web_view);
        }
    }, []);

    return (
        <>
            <WithAuth router={router}>
                {adminPaths.includes(layoutConfig?.path) ? (
                    <FetchedLayout
                        children={<Component {...pageProps} />}
                        {...overrides}
                    />
                ) : (
                    <InvestorContextProvider>
                        <FetchedLayout
                            children={<Component {...pageProps} />}
                            {...overrides}
                        />
                    </InvestorContextProvider>
                )}
            </WithAuth>

            <ToastContainer />
        </>
    );
}

export default MyApp;
