import dynamic from 'next/dynamic';
const EmptyLayout = dynamic(() => import('./EmptyLayout'));
const AdminLayout = dynamic(() => import('./Layout').then((mod) => mod.Layout));
const InvestorLayout = dynamic(() => import('./InvestorLayout'));
const DashboardLayout = dynamic(() => import('./DashboardLayout'));
const StaticLayout = dynamic(() => import('./StaticLayout'));
const NormalLayout = dynamic(() => import('./NormalLayout'));
const NormalLayoutWithFooter = dynamic(() => import('./NormalLayoutWithFooter'));
const ProductPageLayout = dynamic(() => import('./ProductPageLayout'));

const LayoutConfig = [
    {
        path: '/adminLogin',
        Layout: EmptyLayout,
    },
    {
        path: '/admin',
        Layout: AdminLayout,
    },
    {
        path: '/opportunities',
        Layout: DashboardLayout,
    },
    {
        path: '/freecharge',
        Layout: EmptyLayout,
    },
    {
        path: '/payu',
        Layout: EmptyLayout,
    },
    {
        path: '/opportunity',
        Layout: InvestorLayout,
    },
    {
        path: '/kyc',
        Layout: InvestorLayout,
    },
    {
        path: '/dashboard',
        Layout: DashboardLayout,
    },
    {
        path: '/thankyou',
        Layout: InvestorLayout,
    },
    {
        path: '/response',
        Layout: InvestorLayout,
    },
    {
        path: '/Emailverify',
        Layout: InvestorLayout,
    },
    {
        path: '/profile',
        Layout: InvestorLayout,
    },
    {
        path: '/home',
        Layout: EmptyLayout,
    },
    {
        path: '/faq',
        Layout: StaticLayout,
    },
    {
        path: '/ai-safe',
        Layout: StaticLayout,
    },
    {
        path: '/contact-us',
        Layout: StaticLayout,
    },
    {
        path: '/terms-and-condition',
        Layout: StaticLayout,
    },
    {
        path: '/privacy-policy',
        Layout: StaticLayout,
    },
    {
        path: '/risk-disclosure',
        Layout: StaticLayout,
    },
    {
        path: '/disclaimer',
        Layout: StaticLayout,
    },
    {
        path: '/about-us',
        Layout: StaticLayout,
    },
    {
        path: '/team',
        Layout: StaticLayout,
    },
    {
        path: '/careers',
        Layout: StaticLayout,
    },
    {
        path: '/webinars',
        Layout: StaticLayout,
    },
    {
        path: '/accounts',
        Layout: InvestorLayout,
    },
    {
        path: '/blogs',
        Layout: StaticLayout,
    },
    {
        path: '/media',
        Layout: ProductPageLayout,
    },
    {
        path: '/products',
        Layout: ProductPageLayout,
    },
    {
        path: '/investor-ifa-disclaimer',
        Layout: InvestorLayout,
    },
    {
        path: '/create-new-account',
        Layout: NormalLayoutWithFooter,
    },
    {
        path: '/refer-and-earn',
        Layout: InvestorLayout,
    },
    {
        path: '/invitation',
        Layout: NormalLayout,
    },
    {
        path: '/inviteonboardingcomplete',
        Layout: NormalLayout,
    },
    {
        path: '/my-referral',
        Layout: InvestorLayout,
    },
    {
        path: '/credit-evaluation-process',
        Layout: StaticLayout,
    },
    {
        path: '/referandearn',
        Layout: StaticLayout,
    },
    {
        path: '/nri-disclaimer',
        Layout: StaticLayout,
    },
    {
        path: '/start-kyc',
        Layout: InvestorLayout,
    },
    {
        path: '/questionnaire',
        Layout: EmptyLayout,
    },
    {
        path: '/welcome',
        Layout: EmptyLayout,
    },
    {
        path: '/news-letters',
        Layout: StaticLayout,
    },
    {
        path: '',
        Layout: EmptyLayout,
    },
];

const getLayoutConfig = (path, query) => {
    if (query['is_web_view'] !== 'true') {
        let config = LayoutConfig.find((configItem) =>
            path.includes(configItem.path)
        );
        if (config) return config;
    }
    return { Layout: EmptyLayout };
};

export { getLayoutConfig };
